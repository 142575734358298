<template>
  <v-container v-if="project" class="mt-16">
    <v-tabs
      class="my-4 wrapper-tabs"
      hide-slider
      style="box-shadow: none !important"
    >
      <v-tab :href="`#project`">Detalles del proyecto</v-tab>

      <v-tab-item value="project">
        <info-project :init-project="project" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
} from "@vue/composition-api";
import InfoProject from "@/components/worker/project/InfoProject";

import api from "@/services";
import router from "@/router";

export default {
  name: "WorkerProjectDetail",
  components: {
    InfoProject,
  },
  setup() {
    const vm = getCurrentInstance().proxy;

    const project = ref(null);

    const getProjectById = async ({ projectId }) => {
      try {
        const { data: response } = await api.getProjectById(projectId);
        const { data } = response;
        project.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      const { id } = router.currentRoute.params;

      if (id) {
        getProjectById({ projectId: id });
      }
    });

    return {
      project,
    };
  },
};
</script>

<style lang="scss" scope></style>
